<template>
  <section class="hero is-fullheight is-primary">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered is-centered">
          <div class="column is-one-third">
            <div class="card">
              <div class="card-image has-background-primary">
                <section class="section">
                  <figure class="image">
                    <img
                      src="@/assets/images/logo-green.png"
                      alt="Matrix For CRM"
                    >
                  </figure>
                </section>
              </div>
              <div class="card-content">
                <slot name="content" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

  name: 'AuthenticationLayout',

};
</script>

<style lang="scss" scoped></style>
