<template>
  <b-menu>
    <b-menu-list>
      <b-menu-item expanded>
        <template
          slot="label"
          slot-scope="props"
        >
          Security
          <b-icon
            class="is-pulled-right"
            :icon="props.expanded ? 'chevron-down' : 'chevron-up'"
            size="is-small"
          />
        </template>

        <b-menu-item
          tag="router-link"
          exact-active-class="is-active"
          to="/roles"
          label="Roles"
        />

        <b-menu-item
          tag="router-link"
          exact-active-class="is-active"
          to="/permissions"
          label="Permissions"
        />

        <b-menu-item
          tag="router-link"
          exact-active-class="is-active"
          to="/roles-assignment"
          label="Assign Users To Role"
        />
      </b-menu-item>

      <b-menu-item expanded>
        <template
          slot="label"
          slot-scope="props"
        >
          Users
          <b-icon
            class="is-pulled-right"
            :icon="props.expanded ? 'chevron-down' : 'chevron-up'"
            size="is-small"
          />
        </template>

        <b-menu-item
          tag="router-link"
          exact-active-class="is-active"
          to="/users/add"
          label="Add New User"
        />

        <b-menu-item
          tag="router-link"
          exact-active-class="is-active"
          to="/users"
          label="Manage Users"
        />
      </b-menu-item>

      <b-menu-item expanded>
        <template
          slot="label"
          slot-scope="props"
        >
          Organizations
          <b-icon
            class="is-pulled-right"
            :icon="props.expanded ? 'chevron-down' : 'chevron-up'"
            size="is-small"
          />
        </template>

        <b-menu-item
          tag="router-link"
          exact-active-class="is-active"
          to="/organizations/add"
          label="Add New Organization"
        />

        <b-menu-item
          tag="router-link"
          exact-active-class="is-active"
          to="/organizations"
          label="Manage Organizations"
        />
      </b-menu-item>
    </b-menu-list>
  </b-menu>
</template>

<script>
export default {

  name: 'AppSidebar',

};
</script>

<style lang="css" scoped>
</style>
