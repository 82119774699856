<template>
  <div class="columns is-gapless is-mobile">
    <div class="column is-narrow">
      <base-sidebar @open-app-sidebar="openAppSidebar" />
    </div>
    <div
      class="column is-narrow is-sidebar"
      v-if="isActive"
    >
      <app-sidebar />
    </div>
    <div class="column is-scrollable">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import BaseSidebar from '@/components/BaseSidebar.vue';
import AppSidebar from '@/components/AppSidebar.vue';

export default {

  name: 'DashboardLayout',

  components: {
    BaseSidebar,
    AppSidebar,
  },

  data: () => ({
    isActive: false,
  }),

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      this.isActive = false;
    },
  },

  methods: {
    openAppSidebar() {
      this.isActive = !this.isActive;
    },
  },

};
</script>

<style lang="css" scoped>
  .columns {
    height: 100vh;
  }
  .is-scrollable {
    height: 100%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .is-sidebar {
    box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.15);
    z-index: 999;
  }
</style>
