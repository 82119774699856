<template>
  <div class="sidebar">
    <div class="sidebar-logo">
      <figure
        class="image is-48x48"
        @click="$emit('open-app-sidebar')"
      >
        <img
          style="width: 48px; height: 48px;"
          class="is-rounded"
          src="@/assets/images/logo-sidebar.svg"
          alt="Matrix For CRM"
        >
      </figure>
    </div>

    <div class="sidebar-menu">
      <div class="sidebar-menu-list">
        <b-tooltip
          type="is-info"
          label="Roles"
          position="is-right"
        >
          <b-button
            tag="router-link"
            to="/roles"
            active-class="is-active"
            type="is-text"
            icon-right="id-card"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Permissions"
          position="is-right"
        >
          <b-button
            tag="router-link"
            to="/permissions"
            active-class="is-active"
            type="is-text"
            icon-right="key"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Organizations"
          position="is-right"
        >
          <b-button
            tag="router-link"
            to="/organizations"
            active-class="is-active"
            type="is-text"
            icon-right="sitemap"
          />
        </b-tooltip>

        <b-tooltip
          type="is-info"
          label="Users"
          position="is-right"
        >
          <b-button
            tag="router-link"
            to="/users"
            active-class="is-active"
            type="is-text"
            icon-right="users"
          />
        </b-tooltip>
      </div>

      <div class="sidebar-menu-list">
        <b-tooltip
          type="is-info"
          label="Settings"
          position="is-right"
        >
          <b-button
            @click="$emit('open-app-sidebar')"
            type="is-text"
            icon-right="cog"
          />
        </b-tooltip>

        <div class="level is-mobile">
          <div class="level-item has-text-centered">
            <b-dropdown
              aria-role="list"
              position="is-top-right"
            >
              <figure
                class="image is-48x48 sidebar-avatar"
                slot="trigger"
              >
                <img
                  style="width: 48px; height: 48px;"
                  class="is-rounded"
                  :src="profilePic"
                  alt="Username"
                >
              </figure>

              <b-dropdown-item aria-role="listitem">
                <b-button
                  type="is-white"
                  @click="logoutUser"
                >
                  Logout
                </b-button>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defaultImage from '@/assets/images/avatar.svg';

export default {

  name: 'BaseSidebar',

  data: () => ({
    profilePic: localStorage.getItem('user_photo')
      ? localStorage.getItem('user_photo') : defaultImage,
  }),

  methods: {
    logoutUser() {
      this.$store.dispatch('Authentication/logout');
      this.$buefy.notification.open({
        message: 'Successfully logged out',
        type: 'is-success',
      });
      return this.$router.push('/');
    },
  },

};
</script>

<style lang="scss" scoped></style>
